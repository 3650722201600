import React, { lazy, Suspense } from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import { connect } from 'react-redux'

import Layout from 'layouts'

const routes = [
  // Dashboards
  {
    path: '/dashboard',
    Component: lazy(() => import('pages/dashboard/overview')),
    exact: true,
  },
  // User
  {
    path: '/user/list',
    Component: lazy(() => import('pages/user/list')),
    exact: true,
  },
  {
    path: '/user/trash',
    Component: lazy(() => import('pages/user/trash')),
    exact: true,
  },
  {
    path: '/user/add',
    Component: lazy(() => import('pages/user/add')),
    exact: true,
  },
  {
    path: '/user/profile/:id/:tab?',
    Component: lazy(() => import('pages/user/profile')),
    exact: true,
  },
  {
    path: '/user/my-profile/:tab?',
    Component: lazy(() => import('pages/user/my-profile')),
    exact: true,
  },
  // Properties
  {
    path: '/property/list-en',
    Component: lazy(() => import('pages/property/list-en')),
    exact: true,
  },
  {
    path: '/property/list-ar',
    Component: lazy(() => import('pages/property/list-ar')),
    exact: true,
  },
  {
    path: '/property/add',
    Component: lazy(() => import('pages/property/add')),
    exact: true,
  },
  {
    path: '/property/edit/:id',
    Component: lazy(() => import('pages/property/edit')),
    exact: true,
  },
  {
    path: '/property/edit-ar/:id',
    Component: lazy(() => import('pages/property/edit-ar')),
    exact: true,
  },
  {
    path: '/property/featured',
    Component: lazy(() => import('pages/property/featured')),
    exact: true,
  },
  {
    path: '/property/trash',
    Component: lazy(() => import('pages/property/trash')),
    exact: true,
  },
  // Property Types
  {
    path: '/property-type/list',
    Component: lazy(() => import('pages/property-type/list')),
    exact: true,
  },
  {
    path: '/property-type/add',
    Component: lazy(() => import('pages/property-type/add')),
    exact: true,
  },
  {
    path: '/property-type/edit/:id',
    Component: lazy(() => import('pages/property-type/edit')),
    exact: true,
  },
  {
    path: '/property-type/trash',
    Component: lazy(() => import('pages/property-type/trash')),
    exact: true,
  },
  // Developer
  {
    path: '/developer/list-en',
    Component: lazy(() => import('pages/developer/list-en')),
    exact: true,
  },
  {
    path: '/developer/list-ar',
    Component: lazy(() => import('pages/developer/list-ar')),
    exact: true,
  },
  {
    path: '/developer/trash',
    Component: lazy(() => import('pages/developer/trash')),
    exact: true,
  },
  {
    path: '/developer/add',
    Component: lazy(() => import('pages/developer/add')),
    exact: true,
  },
  {
    path: '/developer/edit/:id',
    Component: lazy(() => import('pages/developer/edit')),
    exact: true,
  },
  {
    path: '/developer/edit-ar/:id',
    Component: lazy(() => import('pages/developer/edit-ar')),
    exact: true,
  },
  // Privilege
  {
    path: '/privilege/list',
    Component: lazy(() => import('pages/roles/list')),
    exact: true,
  },
  {
    path: '/privilege/trash',
    Component: lazy(() => import('pages/roles/trash')),
    exact: true,
  },
  {
    path: '/privilege/add',
    Component: lazy(() => import('pages/roles/add')),
    exact: true,
  },
  {
    path: '/privilege/edit/:id',
    Component: lazy(() => import('pages/roles/edit')),
    exact: true,
  },
  // Leads
  {
    path: '/lead/list',
    Component: lazy(() => import('pages/leads/list')),
    exact: true,
  },
  {
    path: '/lead/trash',
    Component: lazy(() => import('pages/leads/trash')),
    exact: true,
  },
  {
    path: '/lead/new',
    Component: lazy(() => import('pages/leads/new')),
    exact: true,
  },
  {
    path: '/lead/unique',
    Component: lazy(() => import('pages/leads/unique')),
    exact: true,
  },
  {
    path: '/lead/duplicate/:email/:phone',
    Component: lazy(() => import('pages/leads/duplicate')),
    exact: true,
  },
  {
    path: '/lead/important',
    Component: lazy(() => import('pages/leads/important')),
    exact: true,
  },
  {
    path: '/lead/followup',
    Component: lazy(() => import('pages/leads/followup')),
    exact: true,
  },
  {
    path: '/lead/import',
    Component: lazy(() => import('pages/leads/import-csv')),
    exact: true,
  },
  // Blog
  {
    path: '/blog/list-en',
    Component: lazy(() => import('pages/blog/list-en')),
    exact: true,
  },
  {
    path: '/blog/list-ar',
    Component: lazy(() => import('pages/blog/list-ar')),
    exact: true,
  },
  {
    path: '/blog/trash',
    Component: lazy(() => import('pages/blog/trash')),
    exact: true,
  },
  {
    path: '/blog/add',
    Component: lazy(() => import('pages/blog/add')),
    exact: true,
  },
  {
    path: '/blog/edit/:id',
    Component: lazy(() => import('pages/blog/edit')),
    exact: true,
  },
  {
    path: '/blog/edit-ar/:id',
    Component: lazy(() => import('pages/blog/edit-ar')),
    exact: true,
  },
  // SEO
  {
    path: '/marketing/pixels',
    Component: lazy(() => import('pages/marketing/pixels-list')),
    exact: true,
  },
  {
    path: '/marketing/pixels/edit/:id',
    Component: lazy(() => import('pages/marketing/pixels-edit')),
    exact: true,
  },
  {
    path: '/marketing/properties-en',
    Component: lazy(() => import('pages/marketing/properties/list-en')),
    exact: true,
  },
  {
    path: '/marketing/properties-ar',
    Component: lazy(() => import('pages/marketing/properties/list-ar')),
    exact: true,
  },
  {
    path: '/marketing/properties/edit-en/:id',
    Component: lazy(() => import('pages/marketing/properties/edit-en')),
    exact: true,
  },
  {
    path: '/marketing/properties/edit-ar/:id',
    Component: lazy(() => import('pages/marketing/properties/edit-ar')),
    exact: true,
  },
  {
    path: '/marketing/developers-en',
    Component: lazy(() => import('pages/marketing/developers/list-en')),
    exact: true,
  },
  {
    path: '/marketing/developers-ar',
    Component: lazy(() => import('pages/marketing/developers/list-ar')),
    exact: true,
  },
  {
    path: '/marketing/developers/edit-en/:id',
    Component: lazy(() => import('pages/marketing/developers/edit-en')),
    exact: true,
  },
  {
    path: '/marketing/developers/edit-ar/:id',
    Component: lazy(() => import('pages/marketing/developers/edit-ar')),
    exact: true,
  },
  {
    path: '/marketing/blog-en',
    Component: lazy(() => import('pages/marketing/blog/list-en')),
    exact: true,
  },
  {
    path: '/marketing/blog-ar',
    Component: lazy(() => import('pages/marketing/blog/list-ar')),
    exact: true,
  },
  {
    path: '/marketing/blog/edit-en/:id',
    Component: lazy(() => import('pages/marketing/blog/edit-en')),
    exact: true,
  },
  {
    path: '/marketing/blog/edit-ar/:id',
    Component: lazy(() => import('pages/marketing/blog/edit-ar')),
    exact: true,
  },
  {
    path: '/marketing/static-pages-en',
    Component: lazy(() => import('pages/marketing/static-pages/list-en')),
    exact: true,
  },
  {
    path: '/marketing/static-pages-ar',
    Component: lazy(() => import('pages/marketing/static-pages/list-ar')),
    exact: true,
  },
  {
    path: '/marketing/static-pages/edit-en/:id',
    Component: lazy(() => import('pages/marketing/static-pages/edit-en')),
    exact: true,
  },
  {
    path: '/marketing/static-pages/edit-ar/:id',
    Component: lazy(() => import('pages/marketing/static-pages/edit-ar')),
    exact: true,
  },
  // Static Pages
  {
    path: '/static-pages/list-en',
    Component: lazy(() => import('pages/static-pages/list-en')),
    exact: true,
  },
  {
    path: '/static-pages/list-ar',
    Component: lazy(() => import('pages/static-pages/list-ar')),
    exact: true,
  },
  {
    path: '/static-pages/trash',
    Component: lazy(() => import('pages/static-pages/trash')),
    exact: true,
  },
  {
    path: '/static-pages/add',
    Component: lazy(() => import('pages/static-pages/add')),
    exact: true,
  },
  {
    path: '/static-pages/edit/:id',
    Component: lazy(() => import('pages/static-pages/edit')),
    exact: true,
  },
  {
    path: '/static-pages/edit-ar/:id',
    Component: lazy(() => import('pages/static-pages/edit-ar')),
    exact: true,
  },
  // Rental Properties
  {
    path: '/rentals/list/:id?',
    Component: lazy(() => import('pages/rentals/list')),
    exact: true,
  },
  {
    path: '/rentals/trash',
    Component: lazy(() => import('pages/rentals/trash')),
    exact: true,
  },
  {
    path: '/rentals/add',
    Component: lazy(() => import('pages/rentals/add')),
    exact: true,
  },
  {
    path: '/rentals/edit/:id',
    Component: lazy(() => import('pages/rentals/edit')),
    exact: true,
  },
  // Rental Payments
  {
    path: '/rentals/payments-all',
    Component: lazy(() => import('pages/rental-payments/list-all')),
    exact: true,
  },
  {
    path: '/rentals/payments/add/:id',
    Component: lazy(() => import('pages/rental-payments/add')),
    exact: true,
  },
  {
    path: '/rentals/payments/edit/:id',
    Component: lazy(() => import('pages/rental-payments/edit')),
    exact: true,
  },
  {
    path: '/rentals/payments/:type/:id',
    Component: lazy(() => import('pages/rental-payments/list')),
    exact: true,
  },
  // Rental Owners
  {
    path: '/rentals/owners',
    Component: lazy(() => import('pages/rentals/list-owner')),
    exact: true,
  },
  // Team
  {
    path: '/team/list',
    Component: lazy(() => import('pages/team/list')),
    exact: true,
  },
  {
    path: '/team/list/:id',
    Component: lazy(() => import('pages/team/list-sub')),
    exact: true,
  },
  {
    path: '/team/add',
    Component: lazy(() => import('pages/team/add')),
    exact: true,
  },
  {
    path: '/team/add/:id',
    Component: lazy(() => import('pages/team/add-sub')),
    exact: true,
  },
  {
    path: '/team/edit/:id',
    Component: lazy(() => import('pages/team/edit')),
    exact: true,
  },
  {
    path: '/team/edit-sub/:id',
    Component: lazy(() => import('pages/team/edit-sub')),
    exact: true,
  },
  {
    path: '/team/member/:id',
    Component: lazy(() => import('pages/team/member')),
    exact: true,
  },
  {
    path: '/my-team',
    Component: lazy(() => import('pages/team/my-team')),
    exact: true,
  },
  // Ecommerce
  {
    path: '/ecommerce/dashboard',
    Component: lazy(() => import('pages/ecommerce/dashboard')),
    exact: true,
  },
  {
    path: '/ecommerce/orders',
    Component: lazy(() => import('pages/ecommerce/orders')),
    exact: true,
  },
  {
    path: '/ecommerce/product-catalog',
    Component: lazy(() => import('pages/ecommerce/product-catalog')),
    exact: true,
  },
  {
    path: '/ecommerce/product-details',
    Component: lazy(() => import('pages/ecommerce/product-details')),
    exact: true,
  },
  {
    path: '/ecommerce/cart',
    Component: lazy(() => import('pages/ecommerce/cart')),
    exact: true,
  },

  // Apps
  {
    path: '/apps/messaging',
    Component: lazy(() => import('pages/apps/messaging')),
    exact: true,
  },
  {
    path: '/apps/calendar',
    Component: lazy(() => import('pages/apps/calendar')),
    exact: true,
  },
  {
    path: '/apps/mail',
    Component: lazy(() => import('pages/apps/mail')),
    exact: true,
  },
  {
    path: '/apps/profile',
    Component: lazy(() => import('pages/apps/profile')),
    exact: true,
  },
  {
    path: '/apps/gallery',
    Component: lazy(() => import('pages/apps/gallery')),
    exact: true,
  },
  // Extra Apps
  {
    path: '/apps/github-explore',
    Component: lazy(() => import('pages/apps/github-explore')),
    exact: true,
  },
  {
    path: '/apps/github-discuss',
    Component: lazy(() => import('pages/apps/github-discuss')),
    exact: true,
  },
  {
    path: '/apps/digitalocean-droplets',
    Component: lazy(() => import('pages/apps/digitalocean-droplets')),
    exact: true,
  },
  {
    path: '/apps/digitalocean-create',
    Component: lazy(() => import('pages/apps/digitalocean-create')),
    exact: true,
  },
  {
    path: '/apps/google-analytics',
    Component: lazy(() => import('pages/apps/google-analytics')),
    exact: true,
  },
  {
    path: '/apps/wordpress-post',
    Component: lazy(() => import('pages/apps/wordpress-post')),
    exact: true,
  },
  {
    path: '/apps/wordpress-posts',
    Component: lazy(() => import('pages/apps/wordpress-posts')),
    exact: true,
  },
  {
    path: '/apps/wordpress-add',
    Component: lazy(() => import('pages/apps/wordpress-add')),
    exact: true,
  },
  {
    path: '/apps/todoist-list',
    Component: lazy(() => import('pages/apps/todoist-list')),
    exact: true,
  },
  {
    path: '/apps/jira-dashboard',
    Component: lazy(() => import('pages/apps/jira-dashboard')),
    exact: true,
  },
  {
    path: '/apps/jira-agile-board',
    Component: lazy(() => import('pages/apps/jira-agile-board')),
    exact: true,
  },
  {
    path: '/apps/helpdesk-dashboard',
    Component: lazy(() => import('pages/apps/helpdesk-dashboard')),
    exact: true,
  },
  // Widgets
  {
    path: '/widgets/general',
    Component: lazy(() => import('pages/widgets/general')),
    exact: true,
  },
  {
    path: '/widgets/lists',
    Component: lazy(() => import('pages/widgets/lists')),
    exact: true,
  },
  {
    path: '/widgets/tables',
    Component: lazy(() => import('pages/widgets/tables')),
    exact: true,
  },
  {
    path: '/widgets/charts',
    Component: lazy(() => import('pages/widgets/charts')),
    exact: true,
  },
  // Cards
  {
    path: '/cards/basic-cards',
    Component: lazy(() => import('pages/cards/basic-cards')),
    exact: true,
  },
  {
    path: '/cards/tabbed-cards',
    Component: lazy(() => import('pages/cards/tabbed-cards')),
    exact: true,
  },
  // UI Kits
  {
    path: '/ui-kits/bootstrap',
    Component: lazy(() => import('pages/ui-kits/bootstrap')),
    exact: true,
  },
  {
    path: '/ui-kits/antd',
    Component: lazy(() => import('pages/ui-kits/antd')),
    exact: true,
  },
  // Tables
  {
    path: '/tables/bootstrap',
    Component: lazy(() => import('pages/tables/bootstrap')),
    exact: true,
  },
  {
    path: '/tables/antd',
    Component: lazy(() => import('pages/tables/antd')),
    exact: true,
  },
  // Charts
  {
    path: '/charts/chartistjs',
    Component: lazy(() => import('pages/charts/chartistjs')),
    exact: true,
  },
  {
    path: '/charts/chartjs',
    Component: lazy(() => import('pages/charts/chartjs')),
    exact: true,
  },
  {
    path: '/charts/c3',
    Component: lazy(() => import('pages/charts/c3')),
    exact: true,
  },
  // Icons
  {
    path: '/icons/feather-icons',
    Component: lazy(() => import('pages/icons/feather-icons')),
    exact: true,
  },
  {
    path: '/icons/fontawesome',
    Component: lazy(() => import('pages/icons/fontawesome')),
    exact: true,
  },
  {
    path: '/icons/linearicons-free',
    Component: lazy(() => import('pages/icons/linearicons-free')),
    exact: true,
  },
  {
    path: '/icons/icomoon-free',
    Component: lazy(() => import('pages/icons/icomoon-free')),
    exact: true,
  },
  // Advanced
  {
    path: '/advanced/form-examples',
    Component: lazy(() => import('pages/advanced/form-examples')),
    exact: true,
  },
  {
    path: '/advanced/email-templates',
    Component: lazy(() => import('pages/advanced/email-templates')),
    exact: true,
  },
  {
    path: '/advanced/utilities',
    Component: lazy(() => import('pages/advanced/utilities')),
    exact: true,
  },
  {
    path: '/advanced/grid',
    Component: lazy(() => import('pages/advanced/grid')),
    exact: true,
  },
  {
    path: '/advanced/typography',
    Component: lazy(() => import('pages/advanced/typography')),
    exact: true,
  },
  {
    path: '/advanced/pricing-tables',
    Component: lazy(() => import('pages/advanced/pricing-tables')),
    exact: true,
  },
  {
    path: '/advanced/invoice',
    Component: lazy(() => import('pages/advanced/invoice')),
    exact: true,
  },
  {
    path: '/advanced/colors',
    Component: lazy(() => import('pages/advanced/colors')),
    exact: true,
  },
  // Auth Pages
  {
    path: '/auth/login',
    Component: lazy(() => import('pages/auth/login')),
    exact: true,
  },
  {
    path: '/auth/forgot-password',
    Component: lazy(() => import('pages/auth/forgot-password')),
    exact: true,
  },
  {
    path: '/auth/register',
    Component: lazy(() => import('pages/auth/register')),
    exact: true,
  },
  {
    path: '/auth/lockscreen',
    Component: lazy(() => import('pages/auth/lockscreen')),
    exact: true,
  },
  {
    path: '/auth/404',
    Component: lazy(() => import('pages/auth/404')),
    exact: true,
  },
  {
    path: '/auth/500',
    Component: lazy(() => import('pages/auth/500')),
    exact: true,
  },
]

const mapStateToProps = ({ settings }) => ({
  routerAnimation: settings.routerAnimation,
})

const Router = ({ history, routerAnimation }) => {
  return (
    <ConnectedRouter history={history}>
      <Layout>
        <Route
          render={state => {
            const { location } = state
            return (
              <SwitchTransition>
                <CSSTransition
                  key={location.pathname}
                  appear
                  classNames={routerAnimation}
                  timeout={routerAnimation === 'none' ? 0 : 300}
                >
                  <Switch location={location}>
                    <Route exact path="/" render={() => <Redirect to="/dashboard" />} />
                    {routes.map(({ path, Component, exact }) => (
                      <Route
                        path={path}
                        key={path}
                        exact={exact}
                        render={() => {
                          return (
                            <div className={routerAnimation}>
                              <Suspense fallback={null}>
                                <Component />
                              </Suspense>
                            </div>
                          )
                        }}
                      />
                    ))}
                    <Redirect to="/auth/404" />
                  </Switch>
                </CSSTransition>
              </SwitchTransition>
            )
          }}
        />
      </Layout>
    </ConnectedRouter>
  )
}

export default connect(mapStateToProps)(Router)
