export default async function getMenuData() {
  return [
    {
      title: 'Dashboard',
      key: 'overview',
      icon: 'fe fe-home',
      url: '/dashboard',
    },
    {
      title: 'Properties',
      key: 'property',
      icon: 'lnr lnr-apartment', // 'fe fe-map-pin',
      section: 'Properties',
      children: [
        {
          title: 'Property List - English',
          key: 'property-list-en',
          url: '/property/list-en',
          section: 'Properties',
          privilege: 'List English',
        },
        {
          title: 'Property List - Arabic',
          key: 'property-list-ar',
          url: '/property/list-ar',
          section: 'Properties',
          privilege: 'List Arabic',
        },
        {
          title: 'Featured Properties',
          key: 'property-featured',
          url: '/property/featured',
          section: 'Properties',
          privilege: 'Featured',
        },
        {
          title: 'Property Types',
          key: 'property-types-list',
          url: '/property-type/list',
          section: 'Property Types',
          privilege: 'List',
        },
        {
          title: 'Deleted Properties',
          key: 'property-trash',
          url: '/property/trash',
          section: 'Properties',
          privilege: 'Trash',
        },
      ],
    },
    {
      title: 'Developers',
      key: 'developer',
      icon: 'fa fa-building-o',
      section: 'Developer',
      children: [
        {
          title: 'Developer List - English',
          key: 'developer-list-en',
          url: '/developer/list-en',
          section: 'Developer',
          privilege: 'List English',
        },
        {
          title: 'Developer List - Arabic',
          key: 'developer-list-ar',
          url: '/developer/list-ar',
          section: 'Developer',
          privilege: 'List Arabic',
        },
        {
          title: 'Deleted Developers',
          key: 'developer-trash',
          url: '/developer/trash',
          section: 'Developer',
          privilege: 'Trash',
        },
      ],
    },
    {
      title: 'Marketing',
      key: 'marketing',
      icon: 'lnr lnr-bullhorn',
      section: 'SEO',
      children: [
        // {
        //   title: 'Marketing Pixels',
        //   key: 'marketing-pixels-list',
        //   url: '/marketing/pixels',
        //   section: 'SEO',
        //   privilege: 'Manage Pixels List',
        // },
        {
          title: 'Static Pages - English',
          key: 'seo-static-pages-list-en',
          url: '/marketing/static-pages-en',
          section: 'SEO',
          privilege: 'Static Page List English',
        },
        {
          title: 'Static Pages Arabic',
          key: 'seo-static-pages-list-ar',
          url: '/marketing/static-pages-ar',
          section: 'SEO',
          privilege: 'Static Page List Arabic',
        },
      ],
    },
    {
      title: 'Leads',
      key: 'leads',
      icon: 'lnr lnr-rocket',
      section: 'Leads',
      children: [
        {
          title: 'Lead List',
          key: 'leads-list',
          url: '/lead/list',
          section: 'Leads',
          privilege: 'List',
        },
        {
          title: 'New Leads',
          key: 'leads-new-list',
          url: '/lead/new',
          section: 'Leads',
          privilege: 'New Leads',
        },
        {
          title: 'Unique Leads',
          key: 'leads-unique-list',
          url: '/lead/unique',
          section: 'Leads',
          privilege: 'Unique Leads',
        },
        {
          title: 'Important Leads',
          key: 'leads-important-list',
          url: '/lead/important',
          section: 'Leads',
          privilege: 'Important Leads',
        },
        // {
        //   title: 'Followup Leads',
        //   key: 'leads-followup-list',
        //   url: '/lead/followup',
        //   section: 'Leads',
        //   privilege: 'Follow up Leads',
        // },
        {
          title: 'Import Leads',
          key: 'leads-import',
          url: '/lead/import',
          section: 'Leads',
          privilege: 'Import CSV',
        },
        {
          title: 'Deleted Leads',
          key: 'leads-trash',
          url: '/lead/trash',
          section: 'Leads',
          privilege: 'Trash',
        },
      ],
    },
    {
      title: 'Users',
      key: 'user',
      icon: 'fe fe-user',
      section: 'User',
      children: [
        {
          title: 'User List',
          key: 'user-list',
          url: '/user/list',
          section: 'User',
          privilege: 'List',
        },
        {
          title: 'Deleted Users',
          key: 'user-trash',
          url: '/user/trash',
          section: 'User',
          privilege: 'Trash',
        },
        {
          title: 'User Privileges',
          key: 'privilege-listing',
          url: '/privilege/list',
          section: 'Privilege',
          privilege: 'List',
        },
        {
          title: 'Team List',
          key: 'team-listing',
          url: '/team/list',
          section: 'Team',
          privilege: 'List',
        },
      ],
    },
    {
      title: 'Rental',
      key: 'rental',
      icon: 'fa fa-tags',
      section: 'Rental',
      children: [
        {
          title: 'Rental Properties',
          key: 'rental-list',
          url: '/rentals/list',
          section: 'Rental',
          privilege: 'List',
        },
        {
          title: 'Deleted Rental Properties',
          key: 'rental-trash',
          url: '/rentals/trash',
          section: 'Rental',
          privilege: 'Trash',
        },
        {
          title: 'Owners',
          key: 'rental-owner-list',
          url: '/rentals/owners',
          section: 'Rental',
          privilege: 'List Owners',
        },
        {
          title: 'Payments',
          key: 'rental-payments-list',
          url: '/rentals/payments-all',
          section: 'Rental',
          privilege: 'List Payments',
        },
      ],
    },
    // {
    //   title: 'Blog',
    //   key: 'blog',
    //   icon: 'fa fa-newspaper-o',
    //   section: 'Blog',
    //   children: [
    //     {
    //       title: 'Posts - English',
    //       key: 'blog-list-en',
    //       url: '/blog/list-en',
    //       section: 'Blog',
    //       privilege: 'List English',
    //     },
    //     {
    //       title: 'Posts - Arabic',
    //       key: 'blog-list-ar',
    //       url: '/blog/list-ar',
    //       section: 'Blog',
    //       privilege: 'List Arabic',
    //     },
    //     {
    //       title: 'Deleted Posts',
    //       key: 'blog-trash',
    //       url: '/blog/trash',
    //       section: 'Blog',
    //       privilege: 'Trash',
    //     },
    //   ],
    // },
    {
      title: 'Static Pages',
      key: 'staticPage',
      icon: 'fa fa-file-text-o',
      section: 'StaticPage',
      children: [
        {
          title: 'Pages - English',
          key: 'static-pages-list-en',
          url: '/static-pages/list-en',
          section: 'StaticPage',
          privilege: 'List English',
        },
        {
          title: 'Pages - Arabic',
          key: 'static-pages-list-ar',
          url: '/static-pages/list-ar',
          section: 'StaticPage',
          privilege: 'List Arabic',
        },
        {
          title: 'Deleted Pages',
          key: 'static-pages-trash',
          url: '/static-pages/trash',
          section: 'StaticPage',
          privilege: 'Trash',
        },
      ],
    },
  ]
}
