import React from 'react'
import LanguageSwitcher from './LanguageSwitcher'
import Actions from './Actions'
import UserMenu from './UserMenu'
import style from './style.module.scss'

const TopBar = () => {
  return (
    <div className={style.topbar}>
      <div className="mr-4">
        <div className={`${style.logoContainer} d-md-none`}>
          <div className={style.logo}>
            <img src="resources/images/logo.png" className="logo mr-2" alt="Logo" />
          </div>
        </div>
      </div>
      <div className="mr-auto">&nbsp;</div>
      <div className="mr-4 d-none">
        <LanguageSwitcher />
      </div>
      <div className="mr-4 d-none d-sm-block">
        <Actions />
      </div>
      <div className="">
        <UserMenu />
      </div>
    </div>
  )
}

export default TopBar
