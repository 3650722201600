import React from 'react'
import { connect } from 'react-redux'
import style from './style.module.scss'

const mapStateToProps = ({ settings }) => ({
  apiServer: settings.apiServer,
  siteURL: settings.siteURL,
  siteURL_ar: settings.siteURL_ar,
  uploadURL: settings.uploadURL,
  company: settings.company,
})

const Footer = props => {
  return (
    <div className={style.footer}>
      <div className={style.footerInner}>
        <p className="mb-0">
          Copyright &copy; {new Date().getFullYear()} {props.company}
        </p>
      </div>
    </div>
  )
}

export default connect(mapStateToProps)(Footer)
